import React, {Fragment} from 'react';
import BreadCrumb from "../../components/BreadCrumb";
import {Link} from "react-router-dom";
import NewsLetter from "../../components/NewsLetter";

// images
import CategoriesWidget from '../../components/CategoriesWidget';

import theKeepImage from '../../new_images/the_keep.jpg';
import thumbsUpImage from '../../new_images/thumbs_up.png';

import '../SingleReviewPage.css';

import CommentSectionReviews from './CommentSectionReviews';
import ThreeRecentStories from '../../components/ThreeRecentStories';

import barbarianPoster from '../../new_images/reviews/barbarian-movie-poster.webp';
import { connect } from 'react-redux';
import AnthologyAvailable from '../../components/AnthologyAvailable';
import InstagramLink from '../InstagramLink';

class ReviewBarbarian extends React.Component {

    constructor(props) {
        super(props);
        this.state = {        
        };
    }

    componentDidMount() {
        // this.props.firebase.analytics().logEvent('page_view', { page_name: window.location.pathname });
    }

    render() {
        return ( 
            <Fragment>
            <div className="archives post post1">
                <BreadCrumb className="padding-top-30" title="Archive / post 1"/>
                <span className="space-30"/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="shadow6">
                                <div className="padding20 dark_footer_bg">
                                    <div className="space-30"/>
                                    <h2 className="review-title">Barbarian Review</h2>
                                    <div className="row">
                                        <div className="col-md-6 pad-bottom-30">
                                            <div className="single_post post_type6 post_type7">
                                                <Link><img src={barbarianPoster} alt="thumb" className="review-book-image"/></Link>
                                                <div className="single_post_text">
                                                    <div className="meta5"><Link to="/">Horror</Link>
                                                        <Link>November 20, 2024</Link>
                                                    </div>
                                                    <h4>
                                                        <Link>Barbarian (Movie) - Zach Cregger</Link>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 pad-bottom-30">
                                            <div className="short-review">
                                                Barbarian is a creatively terrifying film that takes many basic horror premises but gives them a uniquely unsettling spin. Contained excellent performances from the leading cast and compelling cinematography. Good luck falling asleep after watching this one.
                                            </div>
                                            <div className="review-sections">
                                                <div className="review-section">
                                                    <div className="review-section-bar"/>
                                                    <div className="review-section-title">
                                                        OUR RATING
                                                    </div>
                                                    <div className="review-decision">
                                                        <img src={thumbsUpImage} alt="thumb up" className="review-decision-icon"/>
                                                        <div className="review-decision-text">
                                                            8.5 / 10
                                                        </div>
                                                    </div>
                                                    <div className="review-section-bar"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="space-20"/>
                                    <div className="single_post_heading">
                                        <h1>Full Review</h1>
                                    </div>
                                </div>
                                <div className="padding20 dark_footer_bg main-review">
                                            <p>
                                            Creativity is a word that is difficult to attach to many modern-day horror films. Yes, there are newfound methods of killing, a diverse array of the psychopath's costumes, and various methods by which the main character’s means of transportation to safety are sabotaged. That said, most screenplays nowadays feel more or less like a game of Mad Libs. Insert X desolate location, insert the killer’s traumatic history with Y family member, and insert Z unintelligent act by the protagonist putting them in grave danger. I would argue that Barbarian (2022) - Zach Cregger trail blazes a path of its own that is both unique and yet eerily familiar.                                           </p>
                                            <p>
                                            I have to disclose that I’ve never been to Detroit, so I have no way of knowing whether the bleak nature of the city post the auto industry decline was accurate. That being said, I felt that the setting was captivating. As many popular horror films paint being alone in a dense forest as a chilling experience, Barbarian did the same with dilapidated suburbia. Rows upon rows of the skeletal remains of homes gave the feeling that you were constantly being watched by the memories of those who once lived there. This does tie into the plot at a couple of points - which I won’t disclose here - but more than anything I felt that the film did an excellent job of letting the scenery do the heavy lifting of establishing uneasiness in the audience.
                                            </p>
                                            <p>
                                            Just as the scenery does an excellent job of setting the tone, so do the characters. Bill Skarsgard perfectly embodies a character that feels slightly off but isn’t intimidating enough to warrant actual concern. Georgina Campbell plays a character that we all imagine ourselves to be in horror films. She’s aware enough of the peculiarity of her situation to question things but doesn’t put herself in grave danger unless necessary. Okay, fine. She goes into the creepy, dark basement alone for a roll of toilet paper, but she sets up necessary precautions so that she won’t get stuck. I think she embodies how we as viewers believe we would behave if encountering a similar set of circumstances. She makes mistakes but still thinks relatively well on her feet. While she has some empathy, it isn’t enough to completely comprise her.                                            </p>
                                            <p>
                                            More so even than the characters and the scenery, I felt that the cinematography was what took this film to a different level. Spoiler alert: there are a few jump scares. The more terrifying aspect of the film to me, however, were the long shots down endlessly dark hallways or the eerie sounds we have to deal with alone as the protagonist still sleeps. The general feeling of the film was uneasiness, with peaks and valleys of intensity yes, but ultimately you won’t be able to rest until the very end. I would absolutely recommend this film if you’re willing to sacrifice a good night’s sleep in the process.
                                            </p>
                                </div>
                            </div>
                            <div className="space-30"/>
                            {/* <PostOnePagination className="next_prv_single padding20 shadow6 next_prv_single3 dark-2"/> */}
                            <CommentSectionReviews reloadAllStories={this.props.reloadAllStories} allUsers={this.props.allUsers} reviewName="barbarian" currentUser={this.props.currentUser}/>
                        </div>
                        <div className="col-lg-4">
                            <div className="space-50"/>
                            <NewsLetter/>
                            <AnthologyAvailable/>
                            <InstagramLink/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="space-60"/>
        </Fragment>
        )
    }
};

const mapStateToProps = (state) => {
    return {
        allStories: state.primary.allStories,
        allUsers: state.primary.allUsers,
        currentUser: state.primary.currentUser,
        allPrompts: state.primary.allPrompts
    }
}

export default connect (mapStateToProps) (ReviewBarbarian)