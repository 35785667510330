import React, {Fragment} from 'react';
import {Route} from 'react-router-dom';
import MainMenu from "../../components/MainMenu";
import FooterArea from "../../components/FooterArea";
import StickyAccountFooter from '../../components/StickyAccountFooter';

import ErrorBoundary from '../ErrorBoundary';

import { withRouter } from 'react-router-dom';
import { prop } from 'dom7';

const PrivateRoute = (props) => {
    const {component: Component, ...rest} = props;
    console.log(window.location.pathname);
    return (
        <>
        
        {!window.location.pathname.includes('/submit') && (
            <div className={"dark-theme primay_bg"}>
                <Fragment>
                    {/*=== home dark version ===*/}
                    {/* <TopBar dark={true}/> */}
                    <div className="border_white"/>
                    {/* <LogoArea dark={true} className="dark-2"/> */}
                    <MainMenu dark={true} className="dark-2" history={props.history} numNotifications={2}/>
                </Fragment>

                <Route
                    {...rest}
                    render={() => (
                        <ErrorBoundary history={props.history}>
                            <Component history={props.history} pathname={window.location.pathname}/>
                        </ErrorBoundary>
                    )}
                />

                <StickyAccountFooter/>

                <FooterArea className="dark-2"/>
            </div>
        )}

        {window.location.pathname.includes('/submit') && (
            <Route
                {...rest}
                render={() => (
                    <ErrorBoundary history={props.history}>
                        <Component history={props.history}/>
                    </ErrorBoundary>
                )}
            />
        )}
        
        </>
    )
};
export default withRouter(PrivateRoute);
