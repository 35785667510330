import React from 'react';

import thumbUpOutline from '../../new_images/thumbs/thumbs_up_outline.png';
import thumbDownOutline from '../../new_images/thumbs/thumbs_down_outline.png';
import ReviewComments from './ReviewComments';

import '../SingleReviewPage.css';

// import ReviewComments from './ReviewComments';

import './CommentSectionReviews.css';
import { getDBRef } from '../../FirebaseActions';

export default class CommentSectionReviews extends React.Component {

    constructor(props) {
        super(props);
        this.db = getDBRef();
        this.state = {   
            showModal: false,
            thumbUp: false,
            thumbDown: false
        };
        this.thumbUpClicked = this.thumbUpClicked.bind(this);
        this.thumbDownClicked = this.thumbDownClicked.bind(this);
    }

    closeModal() {
        this.setState({showModal: false});
    }

    sendReaction(reaction) {
        //console.log("here")
        let updatedReactions = {};
        let thumbsUpCount = 0;
        let thumbsDownCount = 0;

        if (this.props.currentUser.reactions != null) {
            updatedReactions = this.props.currentUser.reactions;
        }
        updatedReactions[this.props.reviewName] = reaction;

        this.db.collection("users").doc(this.props.currentUser.uid).update({
            reactions: updatedReactions
        })

        this.db.collection("reviews").doc(this.props.reviewName).get().then((doc) => {
            if (doc.exists) {
                let reviewData = doc.data();
                thumbsUpCount = reviewData.thumbUpCount;
                thumbsDownCount = reviewData.thumbDownCount;
            }
        });

        if (reaction) {
            this.db.collection("reviews").doc(this.props.reviewName).update({
                thumbsUpCount: (thumbsUpCount + 1)
            })
        } else {
            this.db.collection("reviews").doc(this.props.reviewName).update({
                thumbsDownCount: (thumbsDownCount + 1)
            })
        }
    }

    thumbUpClicked() {
        if (!this.state.thumbUp && !this.state.thumbDown) {
            if (this.props.currentUser != null) {
                document.getElementById("thumb-up").classList.add("like-dislike-clicked");
                this.setState({thumbUp: true});
                this.sendReaction(true);
            } else {
                this.setState({showModal: true});
            }
        }
    }

    thumbDownClicked() {
        if (!this.state.thumbUp && !this.state.thumbDown) {
            if (this.props.currentUser != null) {
                document.getElementById("thumb-down").classList.add("like-dislike-clicked");
                this.setState({thumbDown: true});
                this.sendReaction(false);
            } else {
                this.setState({showModal: true});
            }
        }
    }

    checkReactions() {
        //console.log(this.props.story);
        //console.log(this.props.currentUser);
        if (this.props.currentUser.reactions != null) {
            for (let id of Object.keys(this.props.currentUser.reactions)) {
                let value = this.props.currentUser.reactions[id];
                //console.log(id, value);
                if (id == this.props.reviewName) {
                    if (value == true) {
                        document.getElementById("thumb-up").classList.add("like-dislike-clicked");
                        this.setState(() => {return {thumbUp: true}});
                    } else {
                        document.getElementById("thumb-down").classList.add("like-dislike-clicked");
                        this.setState(() => {return {thumbDown: true}});
                    }
                }
            }
        }
    }

    componentDidMount() {
        if (this.props.currentUser != null) {
            this.checkReactions();
        }
        //console.log(this.state.thumbDown);
    }

    render() {
        return ( 
            <div className="customer-review-section">
                <h2>What did you think of this story?</h2>
                <div className="thumbs-container">
                    <div className="like-dislike" id="thumb-up">
                        <img src={thumbUpOutline} alt="Thumbs up icon" onClick={this.thumbUpClicked} />
                    </div>
                    <div className="like-dislike" alt="Thumbs down icon" id="thumb-down">
                        <img src={thumbDownOutline} onClick={this.thumbDownClicked}/>
                    </div>
                </div>
                <ReviewComments reloadAllStories={this.props.reloadAllStories} allUsers={this.props.allUsers} currentUser={this.props.currentUser} reviewName={this.props.reviewName}/>
            </div>
        )
    }
}