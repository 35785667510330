import React, {Fragment, useEffect, useState} from 'react';
import FontAwesome from "../uiStyle/FontAwesome";
import tempIcon from '../../doc/img/icon/temp.png';
import {Link, NavLink} from "react-router-dom";
import '../primary-styles.css';
import Button from "react-bootstrap/Button";

import homeLogo1 from '../../new_images/branding/StrangestFictionFinalVersion.png';

import penIcon from '../../new_images/icons/pen-icon.png';
import userImage from '../../new_images/white-user-image.png';
import searchIcon from '../../new_images/icons/white-search-icon.png';
import closeIcon from '../../new_images/icons/close-icon.png';
import submitIcon from '../../new_images/icons/submit.png';

import NewSideBar from '../NewSideBar';
import { connect } from 'react-redux';

const menusDark = [
    {
        id: 1,
        linkText: 'Home',
        link: '/'
    },
    // {
    //     id: 1,
    //     linkText: 'Home',
    //     child: true,
    //     icon: 'angle-down',
    //     submenu: [
    //         {
    //             id: 11,
    //             link: '/',
    //             linkText: 'Home 1'
    //         },
    //         {
    //             id: 12,
    //             link: '/dark',
    //             linkText: 'Home Dark'
    //         },
    //         {
    //             id: 13,
    //             new: true,
    //             link: '/home-two',
    //             linkText: 'Home 2'
    //         },
    //         {
    //             id: 14,
    //             link: '/home-three',
    //             linkText: 'Home 3'
    //         },
    //     ]
    // },
    {
        id: 2,
        linkText: 'Short Stories',
        child: true,
        icon: 'angle-down',
        submenu: [
            {
                id: 40,
                linkText: 'All Stories',
                link: '/allstories'
            },
            {
                id: 42,
                link: '/category/scifi',
                linkText: 'Science Fiction'
            },
            {
                id: 43,
                link: '/category/supernatural',
                linkText: 'Supernatural'
            },
            {
                id: 44,
                link: '/category/horror',
                linkText: 'Horror'
            },
            {
                id: 45,
                link: '/category/fantasy',
                linkText: 'Fantasy'
            },
            {
                id: 46,
                link: '/category/thriller',
                linkText: 'Thriller'
            },
            {
                id: 42,
                link: '/writers',
                linkText: 'Writers'
            },
        ]
    },
    {
        id: 4,
        linkText: 'Store',
        child: true,
        icon: 'angle-down',
        submenu: [
            {
                id: 47,
                linkText: 'Publications',
                link: '/publications'
            },
            {
                id: 48,
                linkText: 'Merch',
                link: '/merch'
            }
        ]
    },
    {
        id: 65,
        linkText: 'Archive',
        child: true,
        icon: 'angle-down',
        submenu: [
            {
                id: 49,
                linkText: 'Competitions',
                link: '/competitions'
            },
            {
                id: 41,
                linkText: 'Prompts',
                link: '/prompts'
            },
            {
                id: 47,
                link: '/category/histfic',
                linkText: 'Historical Fiction'
            },
        ]
    },
    {
        id: 3,
        linkText: 'News',
        link: '/announcements'
    },
    {
        id: 66,
        linkText: 'Reviews',
        link: '/reviews'
    },

    // {
    //     id: 4,
    //     linkText: 'Reviews',
    //     link: '/reviews'
    // },
];

class MainMenu extends React.Component {
    constructor(props) {
        super(props);
        this.searchRef = React.createRef();
        this.searchStoriesRef = React.createRef();
        this.searchUsersRef = React.createRef();

        this.state = {        
            showModal: false,
            showModalFromWrite: false,
            loginWasSuccess: false,
            sideShow: false,
            matchingStories: [],
            matchingUsers: [],
            searchStories: true,
            searchUsers: false
        };
        this.setSideShow = this.setSideShow.bind(this);
        this.searchClicked = this.searchClicked.bind(this);
        this.closeSearch = this.closeSearch.bind(this);
        this.changedSearchCriteria = this.changedSearchCriteria.bind(this);
        this.storyClicked = this.storyClicked.bind(this);
    }

    setSideShow(val) {
        this.setState(() => { return { sideShow: val}});
    }
    
    showModal() {
        // this.setState(() => { return { showModal: true}});
        this.props.dispatch({type: "SET_LOGIN_MODALS", payload: {showLoginModal: true, showCreateAccount: true, showSuccess: false, showPassReset: false}});
    }

    showModalFromWrite() {
        // this.setState(() => { return { showModalFromWrite: true}});
        this.props.dispatch({type: "SET_LOGIN_MODALS", payload: {showLoginModal: true, showCreateAccount: true, showSuccess: false, showPassReset: false}});
    }

    closeModal() {
        this.setState(() => { return {showModal: false}})
    }

    closeModalFromWrite() {
        this.setState(() => { return { showModalFromWrite: false}})
        //console.log(this.state.loginWasSuccess)
        if (this.state.loginWasSuccess) {
            setTimeout(() => {
                this.props.history.push('/write');
            }, 300)
            this.setState({loginWasSuccess: false});
        }
    }

    wasSuccess() {
        this.setState(() => { return { loginWasSuccess: true}})
    }

    searchClicked() {
        this.searchRef.current.style.display="block";
        document.getElementById("search-bar").focus();
    }

    closeSearch() {
        this.searchRef.current.style.display="none";
    }

    changedSearchCriteria(event) {
        const query = event.target.value.toLowerCase();
        if (!(query === "") && this.state.searchStories) {
            let filteredItems = this.props.allStories.filter((story) => {
                return story.allStoryInformation.title.toLowerCase().indexOf(query) > -1;
            })
            this.setState({matchingStories: filteredItems});
        } else if (!(query === "") && this.state.searchUsers) {
            let filteredItems = this.props.allUsers.filter((user) => {
                return user.username.toLowerCase().indexOf(query) > -1 || user.fullname.toLowerCase().indexOf(query) > -1;
            })
            this.setState({matchingUsers: filteredItems});
        }
    }

    storyClicked(story) {
        this.searchRef.current.style.display="none";
        this.props.history.push('/story/' + story.id);
    }

    userClicked(user) {
        this.searchRef.current.style.display="none";
        this.props.history.push('/user/' + user.uid);
    }

    setSearchOption(buttonRef, refToDelete) {
        if (!buttonRef.current.classList.contains("highlighted")) {
            buttonRef.current.classList.add("highlighted");
        }
        refToDelete.current.classList.remove("highlighted");
    }

    render() {
        return ( 
        <Fragment>
            <div className={`main-menu dark-2`} id="header">
                <Link to="#top" className="up_btn up_btn1 blue-background">
                    <FontAwesome name="chevron-double-up"/>
                </Link>
                <div className="main-nav clearfix is-ts-sticky make-sticky">
                    <div className="container no-padding">
                        <div className="row justify-content-between">
                            <nav className="navbar navbar-expand-lg col-xl-6 col-lg-8 align-self-center">
                                <div className="site-nav-inner">
                                    <button className="navbar-toggler" onClick={() => this.setSideShow(!this.state.sideShow)}>
                                        {this.state.sideShow && (
                                            <FontAwesome name="times fa-2x"/>
                                        )}
                                        {!this.state.sideShow && (
                                            <FontAwesome name="bars fa-2x"/>
                                        )}

                                    </button>
                                    <div id="navbarSupportedContent"
                                         className="collapse navbar-collapse navbar-responsive-collapse">
                                        <ul className="nav navbar-nav" id="scroll">
                                            {menusDark.length > 0 ? menusDark.map((item, i) => (
                                                <li key={i}
                                                    className={`
                                                ${item.child ? 'dropdown' : ''}
                                                nav-item`}>
                                                    {item.child ? <NavLink onClick={e => e.preventDefault()} to="/"
                                                                           className="menu-dropdown"
                                                                           data-toggle="dropdown">{item.linkText}
                                                            <FontAwesome
                                                                name={item.icon}/></NavLink>
                                                        : <NavLink to={item.link} className="menu-dropdown"
                                                                   data-toggle="dropdown">
                                                                         {item.linkText == "Home" && (
                                                                               <img src={homeLogo1} alt="Home logo" className="home-logo" />
                                                                           )}
                                                                           {item.linkText != "Home" && (
                                                                                <>
                                                                                    {item.linkText}
                                                                                </>
                                                                           )}
                                                                    <FontAwesome
                                                            name={item.icon}/></NavLink>}

                                                    {item.child ?
                                                        <ul className="dropdown-menu" role="menu">
                                                            {item.submenu.map((sub_item, i) => (
                                                                <li key={i}
                                                                    className={`${sub_item.child ? 'dropdown-submenu' : null}
                                                        `}>
                                                                    {sub_item.child ?
                                                                        <NavLink onClick={e => e.preventDefault()}
                                                                                 to="/">{sub_item.linkText}</NavLink>
                                                                        : <NavLink
                                                                            to={sub_item.link}>{sub_item.linkText}</NavLink>}
                                                                    {sub_item.third_menu ?
                                                                        <ul className="dropdown-menu">
                                                                            {sub_item.third_menu.map((third_item, i) => (
                                                                                <li key={i}><NavLink
                                                                                    to={third_item.link}>{third_item.linkText}</NavLink>
                                                                                </li>
                                                                            ))}
                                                                        </ul> : null}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                        : null
                                                    }
                                                </li>
                                            )) : null}
                                        </ul>
                                        <img src={searchIcon} alt="temp icon" className="search-icon" onClick={this.searchClicked}/>
                                    </div>
                                    {/* <SidebarMenu sideShow={sideShow} setSideShow={setSideShow} menus={arr}/> */}
                                </div>
                            </nav>
                            {/* {window.screen.width > 1200 && (
                                <div className='col-xl-2'>
                                    <PayPalDonationButton/>
                                </div>
                            )} */}
                            <div className="col-lg-4 align-self-center">
                                <div className="navbar-button-row">
                                    
                                    

                                    {this.props.currentUser == null && (
                                        <>
                                            {/* <div className="top-write-button" onClick={() => {this.showModalFromWrite()}}>
                                                <img src={penIcon} alt="temp icon" className="pen-icon"/>
                                                <div className="top-button-text">
                                                    Write
                                                </div>
                                            </div> */}
                                            <div className="top-write-button" onClick={() => {this.props.history.push('/submit/rolling');}}>
                                                <img src={submitIcon} alt="temp icon" className="pen-icon"/>
                                                <div className="top-button-text">
                                                    Submit
                                                </div>
                                            </div>
                                            <div className="top-login-button" onClick={() => {this.showModal()}}>
                                                <img src={userImage} alt="temp icon" className="pen-icon"/>

                                                <div className="top-button-text">
                                                    Login
                                                </div>
                                                
                                            </div>
                                        </>
                                    )}
                                    {this.props.currentUser != null && (
                                        <>
                                            {/* <Link className="top-write-button" to="/write">
                                                <img src={penIcon} alt="temp icon" className="pen-icon"/>
                                                <div className="top-button-text">
                                                    Write
                                                </div>
                                            </Link> */}
                                            <div className="top-write-button" onClick={() => {this.props.history.push('/submit/rolling');}}>
                                                <img src={submitIcon} alt="temp icon" className="pen-icon"/>
                                                <div className="top-button-text">
                                                    Submit
                                                </div>
                                            </div>
                                            <Link className="top-login-button" to="/account">
                                                {this.props.currentUser.imgUrl != null && (
                                                    <img src={this.props.currentUser.imgUrl} alt="temp icon" className="small-user-icon"/>
                                                )}
                                                {this.props.currentUser.imgUrl == null && (
                                                    <img src={userImage} alt="temp icon" className="pen-icon"/>
                                                )}
                                                {window.screen.width >= 425 && (
                                                    <div className="top-button-text">
                                                        {this.props.currentUser.username}
                                                    </div>
                                                )}
                                                {window.screen.width < 425 && (
                                                    <div className="top-button-text">
                                                        Account
                                                    </div>
                                                )}
                                                {this.props.currentUser.notifications?.hasActive || this.props.currentUser?.hasActiveMessage && (
                                                    <div className="floating-notification">
                                                        <div className='notification-dot'/>
                                                    </div>
                                                )}
                                                {/* {this.props.numNotifications != 0 && (
                                                    <div className="notifications-icon">
                                                        <div className="num-notifications">
                                                            {this.props.numNotifications}
                                                        </div>
                                                    </div>
                                                )} */}
                                            </Link>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="search-container" ref={this.searchRef}>
                            <div className="flex-row">
                                <input type="text" onChange={this.changedSearchCriteria} placeholder="Start searching here..." id="search-bar"/>
                                <img src={closeIcon} alt="temp icon" className="close-icon" onClick={this.closeSearch}/>
                            </div>
                            <div className="search-options">
                                <div className="option highlighted" ref={this.searchStoriesRef} onClick={() => {this.setSearchOption(this.searchStoriesRef, this.searchUsersRef); this.setState({searchUsers: false, searchStories: true})}}>Search Stories</div>
                                <div className="divider-dot">-</div>
                                <div className="option" ref={this.searchUsersRef} onClick={() => {this.setSearchOption(this.searchUsersRef, this.searchStoriesRef); this.setState({searchUsers: true, searchStories: false})}}>Search Users</div>
                            </div>
                            {this.state.searchStories && (
                                <>
                                    {this.state.matchingStories.length != 0 && this.state.matchingStories.map((story, index) => {
                                        return (
                                            <>
                                                <div className="account-tab-border" />
                                                <div className="searched-story" onClick={() => {this.storyClicked(story)}}>
                                                    <h4>{story.allStoryInformation.title}</h4>
                                                    <div>Story by {story.allStoryInformation.username}</div>
                                                </div>
                                                <div className="account-tab-border" />
                                            </>
                                        )
                                    })}
                                    {this.state.matchingStories.length == 0 && (
                                        <h4 className="no-stories-match">No stories match your search criteria.</h4>
                                    )}
                                </>
                            )}
                            {this.state.searchUsers && (
                                <>
                                    {this.state.matchingUsers.length != 0 && this.state.matchingUsers.map((user, index) => {
                                        return (
                                            <>
                                                <div className="account-tab-border" />
                                                <div className="searched-story" onClick={() => {this.userClicked(user)}}>
                                                    {user.fullname != "" && (
                                                        <h4>{user.fullname}</h4>
                                                    )}
                                                    <div>{user.username}</div>
                                                </div>
                                                <div className="account-tab-border" />
                                            </>
                                        )
                                    })}
                                    {this.state.matchingUsers.length == 0 && (
                                        <h4 className="no-stories-match">No users match your search criteria.</h4>
                                    )}
                                </>
                            )}
                            
                        </div>
                    </div>
                    
                    <NewSideBar sideShow={this.state.sideShow} setSideShow={this.setSideShow}/>
                </div>
            </div>
        </Fragment>
    );
};
}

const mapStateToProps = (state) => {
    return {
        allStories: state.primary.allStories,
        allUsers: state.primary.allUsers,
        checkForUser: state.primary.checkForUser,
        currentUser: state.primary.currentUser
    }
}

export default connect (mapStateToProps) (MainMenu)